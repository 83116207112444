<template>

  <div>
    <img src="/static/quota.svg" class="mt-2 mb-4 mx-auto">
  </div>
  <p class="text-center">Social experiment that turns knowledge and hours into valued assets. </p>
  <hr class="w-32 mx-auto my-5">
  <div class="flex md:flex-row flex-col">
  <div class="md:w-1/2 w-full p-2 md:mr-5">

  <p class="mb-2 font-bold">Join Whitelist</p>
  <p>Access the QUOTA Ambassadors group in Telegram.</p>

  </div>
  <div class="md:w-1/2  w-full p-2">
  
  <div v-if="errorMessage" class="bg-yellow-200 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-5" role="alert">
    <p class="font-bold">Warning</p>
    <p>{{ errorMessage }}</p>
  </div>
  <div v-if="successMessage" class="bg-green-200 border-l-4 border-green-500 text-green-700 p-4 mb-5" role="alert">
    <p class="font-bold">Thanks!</p>
    <p>{{ successMessage }} <a :href="successLink" class="text-bold underline">{{ successLinkText }}</a></p>
  </div>
  <div v-if="networkError" class="bg-red-200 border-l-4 border-red-500 text-red-700 p-4 mb-5" role="alert">
    <p class="font-bold">Error</p>
    <p>{{ networkError }}</p>
  </div>
  <form v-if="!successMessage"  @submit.prevent="saveEmail" class="flex flex-col">
    <div class="mb-4">
      <!--
        <label for="email" class="block text-gray-700 font-bold mb-2">Email:</label>
      -->
      <input id="email" type="email" v-model="email" placeholder="Enter your email" required class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
    </div>
    <button type="submit" class="bg-orange-700 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Notify Me</button>
  </form>


</div>
</div>
</template>

<script>
import axios from 'axios';

export default {
name: 'HelloWorld',
data() {
  return {
    email: '',
    errorMessage: '',
    successMessage: '',
    networkError: ''
  }
},
methods: {
  saveEmail() {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,2,3,4,5,6,7,8,9}\.])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(this.email.toLowerCase())) {
     
      const email_addr = this.email;
      const config = process.env.NODE_ENV === 'production' ? require('/config').server : require('/config').local;

      axios.post(config.url, {
          email: email_addr,
      })
      .then(response => {
        this.successMessage = 'Please join our';
        this.successLink = response.data;
        this.successLinkText = 'Telegram group';
        this.errorMessage = '';
        this.email = '';
      })
      .catch(error => {
        this.networkError = error;
        this.successMessage = '';
      });
      
    } else {
      this.errorMessage = 'Incorrect email format';
      this.successMessage = '';
    }
  }
}
}
</script>

<!-- existing style section -->
