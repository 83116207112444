<template>
  <div class="home bg-gray-200 min-h-screen flex items-center justify-center">
    <div class="bg-white w-[50%] md:w-[70%] min-w-[360px] max-w-[600px] shadow-lg p-6 mt-10">
      <HelloWorld/>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import axios from 'axios';

export default {
  name: 'HomeView',
  data() {
    return {
      email: '',
      errorMessage: '',
      successMessage: '',
      networkError: ''
    }
  },
  components: {
    HelloWorld
  }
}
</script>
